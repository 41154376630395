<template>
	<div>
		<h1>Configuration</h1>

		<vue-good-table
			mode="remote"
			styleClass="vgt-table striped bordered condensed"
			:theme="vgtTheme"
			v-on:page-change="onPageChange"
			v-on:sort-change="onSortChange"
			v-on:column-filter="onColumnFilter"
			v-on:per-page-change="onPerPageChange"
			:totalRows="records"
			v-model:isLoading.sync="loading"
			:pagination-options="{
				enabled: true,
				perPage: 1000,
				perPageDropdown: [10, 25, 50, 100, 1000],
				setCurrentPage: initial_page
			}"
			:sortOptions="{
				enabled: true,
				multipleColumns: true,
				initialSortBy: initial_sort
			}"
			:rows="rows"
			:columns="columns"
			:key="vgt_reload_count">
			<template #table-row="props">
				<div v-if="props.column.field == 'config_label' || props.column.field == 'config_key'">
					<router-link :to="'/config/' + props.row.config_id">{{props.formattedRow[props.column.field]}}</router-link>
				</div>
				<div v-else>
					{{props.formattedRow[props.column.field]}}
				</div>
			</template>
		</vue-good-table>
	</div>
</template>

<script>
	import 'vue-good-table-next/dist/vue-good-table-next.css'
	import { VueGoodTable } from 'vue-good-table-next';

	export default {
		name: 'Config',
		components: {
			VueGoodTable
		},
		data() {
			return {
				loading: false,
				columns: [
					{
						label: "Configuration Label",
						field: "config_label",
						sortable: true,
						filterOptions:
						{
							enabled: true
						}
					},
					{
						label: "Configuration Key",
						field: "config_key",
						sortable: true,
						filterOptions:
						{
							enabled: true
						}
					},
					{
						label: "Configuration Value",
						field: "config_value",
						sortable: true,
						filterOptions:
						{
							enabled: true
						}
					}
				],
				rows: [],
				records: 0,
				initial_sort: [
					{field: 'config_label', type: 'asc'},
					{field: 'config_value', type: 'asc'}
				],
				initial_page: 1
			}
		},
		created()
		{
			this.load();
		},
		methods: {
			load()
			{
				// Avoid some VGT events from loading more than once.
				if(this.loading)
				{
					return;
				}

				this.loading = true;
				this.$store.commit("startLoading");

				this.CORS("POST", "/config/filtered", JSON.stringify(this.search_params),
				(response) =>
				{
					this.records = response.records;
					this.rows = response.rows;
					this.loading = false;
					this.$store.commit("stopLoading");
				},
				(response) =>
				{
					this.showError("Error Loading Configuration", response.responseText, true, null);
					this.loading = false;
					this.$store.commit("stopLoading");
				})
			}
		}
	}
</script>
